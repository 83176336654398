<template>
<div>
<br>
  <v-carousel>
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      transition="fade-transition"
    ></v-carousel-item>
  </v-carousel>
  </div>
</template>


<script>
  export default {
    name: 'Home',
    data () {
      return {
        items: [
          {
             src: require('@/assets/image/japanese-style_house.jpg')
            ,
          },
          {
           
            src: require('@/assets/image/japanese-style_room.jpg')
          },
          {
            src: require('@/assets/image/japanese-style_room2.jpg'),
            
          },
          {
            src: require('@/assets/image/mountain.jpg'),
          },
        ],
      }
    },
  }
</script>
