<template>
<div class="Main">
  <v-app>
  <v-navigation-drawer app v-model="drawer"  dark
      src="@/assets/image/bamboo.jpg"
      
      >
      <template v-slot:img="{ height, src }">
  <v-img :height="height" :src="src" gradient="to top right, rgba(0,0,0,.9), rgba(0,0,0,.7)"></v-img>
</template>
   <v-container>
    <v-list-item>
      <v-list-item-content>
       <v-list-item-title class="title  text--darken-2">
        メニュー
       </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
  <v-list nav dense>
  <v-list-group 
  v-for="nav_list in nav_lists" 
  :key="nav_list.name" 
  :prepend-icon="nav_list.icon" 
  no-action 
  :append-icon="nav_list.lists ? undefined : ''"> 
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item v-for="list in nav_list.lists" :key="list.name" :to="list.link">
      <v-list-item-content>
        <v-list-item-title>{{ list.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</v-list>
  </v-container>
</v-navigation-drawer>
    <v-app-bar app
      absolute
      dark
      
      src="@/assets/image/japanese-paper_blue.jpg"
      scroll-target="#scrolling-techniques-2"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          
        ></v-img>
      </template>
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>寺務管理アプリ</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
<v-btn text to="/enterprise">アカウント情報</v-btn>
<v-menu offset-y>
  <template v-slot:activator="{on}">
  <v-btn v-on="on" text>サポート<v-icon>mdi-menu-down</v-icon></v-btn>
  </template>
<v-list>
<v-subheader>Get help</v-subheader>
  <v-list-item v-for="support in supports" :key="support.name"  @click="getIp">
  <v-list-item-icon>
  <v-icon>{{ support.icon }}</v-icon>
  </v-list-item-icon>
  <v-list-item-content>
  <v-list-item-title>{{ support.name }}</v-list-item-title>
  </v-list-item-content>
</v-list-item>
</v-list>
</v-menu>
</v-toolbar-items>
    </v-app-bar>
    <v-main>
    <router-view />
    </v-main>
  </v-app>
  </div>
</template>

<script>
export default {
  data(){
    return{
        drawer: null,
        supports:[
          {name: 'Consulting and suppourt',icon: 'mdi-vuetify'},
          {name: 'Discord community',icon: 'mdi-discord'},
          {name: 'Report a bug',icon: 'mdi-bug'},
          {name: 'Github issue board',icon: 'mdi-github'},
          {name: 'Stack overview',icon: 'mdi-stack-overflow'},
                ],
        nav_lists:[
          {
            name: 'ホーム',
            icon: 'mdi-home',
                 lists:[{name: "ホーム",
                    link:'/'},
                  {name: "始め方",
                   link:'/clienttable'}
                
                ]
          },
          {
          name: 'スケジュール管理',
          icon: 'mdi-calendar-text ',
          lists:[{name: "スケジュール表",
                 link:'/schedule'},
                 {name: "スケジュール管理",
                 link:'/',
                }]
      
          },
          {
            name: '名簿管理',
            icon: 'mdi-account-multiple' ,
            lists:[{name: "檀家名簿管理",
                    link:'/membertable'},
                  {name: "提携先名簿",
                   link:'/clienttable'}
                
                ]
          },
          {
            name: '財務管理',
            icon: 'mdi-file-document-edit',
             lists:[{name: '財務管理表',link:'/membertable'},
                {name:'雑費管理',link:'/moneyinput'
                }]
          },
          {
          name: '年回忌/法要管理',
          icon: 'mdi-view-dashboard',
          lists:[{name: 'Quick Start',link:'/about'},
                {name:'Pre-made layouts',link:'/membertable'
                }]
          },
          {
          name: '設定',
          icon: 'mdi-cogs',
          },
          ]
    }
  },
      methods: {
      getIp() {
        //this.axios.get('https://httpbin.org/get')
        this.axios.get('http://127.0.0.1:5001')
          .then((response) => {
            alert(response.data);
          })
          .catch((e) => {
            alert(e);
          });
      }
    }
}
</script>