var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Main"},[_c('v-app',[_c('v-navigation-drawer',{attrs:{"app":"","dark":"","src":require("@/assets/image/bamboo.jpg")},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var height = ref.height;
var src = ref.src;
return [_c('v-img',{attrs:{"height":height,"src":src,"gradient":"to top right, rgba(0,0,0,.9), rgba(0,0,0,.7)"}})]}}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-container',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title  text--darken-2"},[_vm._v(" メニュー ")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.nav_lists),function(nav_list){return _c('v-list-group',{key:nav_list.name,attrs:{"prepend-icon":nav_list.icon,"no-action":"","append-icon":nav_list.lists ? undefined : ''},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(nav_list.name))])],1)]},proxy:true}],null,true)},_vm._l((nav_list.lists),function(list){return _c('v-list-item',{key:list.name,attrs:{"to":list.link}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(list.name))])],1)],1)}),1)}),1)],1)],1),_c('v-app-bar',{attrs:{"app":"","absolute":"","dark":"","src":require("@/assets/image/japanese-paper_blue.jpg"),"scroll-target":"#scrolling-techniques-2"},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({},'v-img',props,false))]}}])},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer=!_vm.drawer}}}),_c('v-toolbar-title',[_vm._v("寺務管理アプリ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":"","to":"/enterprise"}},[_vm._v("アカウント情報")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("サポート"),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',[_c('v-subheader',[_vm._v("Get help")]),_vm._l((_vm.supports),function(support){return _c('v-list-item',{key:support.name,on:{"click":_vm.getIp}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(support.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(support.name))])],1)],1)})],2)],1)],1)],1),_c('v-main',[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }